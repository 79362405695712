import { createFileRoute, Outlet } from "@tanstack/react-router";
import AuthorizeGuard from "@core/guards/AuthorizationGuard.tsx";
import Meta from "@components/layout/common/Meta.tsx";

export const Route = createFileRoute("/(main)/startgreen-by-iptic")({
	component: () => (
		<>
			<Meta favicon="/startgreen.ico" faviconKey={"startgreen"} />
			<AuthorizeGuard>
				<Outlet />
			</AuthorizeGuard>
		</>
	),
});
