import type React from "react";
import Step1 from "@pages/(startgreen)/views/(form)/(visitor)/Step1.tsx";
import Step3 from "@pages/(startgreen)/views/(form)/(visitor)/Step3.tsx";
import ProgressBar from "@pages/(startgreen)/views/(form)/(visitor)/ProgressBar.tsx";
import { Link } from "@tanstack/react-router";

interface CandidateFormProps {
	step: number;
	nextStep: () => void;
	prevStep: () => void;
	formData: any;
	setFormData: (data: any) => void;
}

const VisitorForm: React.FC<CandidateFormProps> = ({
	step,
	nextStep,
	prevStep,
	formData,
	setFormData,
}) => {
	return (
		<>
			<ProgressBar step={step} />
			{step === 1 && (
				<Step1
					nextStep={nextStep}
					formData={formData}
					setFormData={setFormData}
				/>
			)}
			{/*{step === 2 && (*/}
			{/*	<Step2*/}
			{/*		nextStep={nextStep}*/}
			{/*		prevStep={prevStep}*/}
			{/*		formData={formData}*/}
			{/*		setFormData={setFormData}*/}
			{/*	/>*/}
			{/*)}*/}
			{step === 2 && (
				<Step3 nextStep={nextStep} prevStep={prevStep} formData={formData} />
			)}
			{step === 3 && (
				<div
					className={
						"flex flex-col items-center justify-center bg-white rounded-md p-7 gap-4"
					}
				>
					<div className={"flex flex-col items-center gap-3"}>
						<svg
							width="158"
							height="145"
							viewBox="0 0 158 145"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								opacity="0.5"
								x="14.3691"
								y="0.767822"
								width="51.5427"
								height="51.5427"
								rx="10"
								fill="#87C443"
							/>
							<rect
								opacity="0.5"
								x="128.32"
								y="33.6379"
								width="29.3594"
								height="29.3594"
								rx="10"
								fill="#87C443"
							/>
							<rect
								opacity="0.5"
								x="0.320312"
								y="75.6379"
								width="31.5329"
								height="31.5329"
								rx="8"
								fill="#068A8F"
							/>
							<rect
								opacity="0.5"
								x="114.607"
								y="107.589"
								width="36.5935"
								height="36.5935"
								rx="8"
								fill="#068A8F"
							/>
							<circle cx="83.7969" cy="79.1433" r="60" fill="#006837" />
							<g filter="url(#filter0_d_10_6757)">
								<path
									d="M61.0801 82.388L74.0606 95.3685L106.512 62.9172"
									stroke="white"
									stroke-width="8"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
							<defs>
								<filter
									id="filter0_d_10_6757"
									x="27.0801"
									y="32.9172"
									width="113.432"
									height="100.451"
									filterUnits="userSpaceOnUse"
									color-interpolation-filters="sRGB"
								>
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix
										in="SourceAlpha"
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
										result="hardAlpha"
									/>
									<feOffset dy="4" />
									<feGaussianBlur stdDeviation="15" />
									<feColorMatrix
										type="matrix"
										values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0"
									/>
									<feBlend
										mode="normal"
										in2="BackgroundImageFix"
										result="effect1_dropShadow_10_6757"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect1_dropShadow_10_6757"
										result="shape"
									/>
								</filter>
							</defs>
						</svg>
						<h2 className={"text-center "}>
							Votre demande de participation en tant que membre invité a été
							soumise avec succès. Nous reviendrons vers vous prochainement.
						</h2>
					</div>

					<Link to={"/startgreen-by-iptic"}>
						<button
							type="submit"
							className="bg-startgreen_primary-100 hover:bg-startgreen_primary-100/90 text-white px-6 py-3 rounded-md mt-4"
						>
							Retour à l'accueil
						</button>
					</Link>
				</div>
			)}
		</>
	);
};

export default VisitorForm;
