import React, { useEffect, useState } from "react";
import useAuth from "@core/guards/auth.util.ts";
import { getPlatformStatus } from "@api/service/startgreen.services.ts";
import Spinner from "@components/elements/Spinner";

const AuthorizeGuard = ({ children }) => {
	const { isAuthorized, isAuthLoading, handleAuthorization } = useAuth();
	const [input, setInput] = useState("");
	// const [loading, setLoading] = useState(true);

	// useEffect(() => {
	// 	const fetchPlatformStatus = async () => {
	// 		try {
	// 			const statusData = await getPlatformStatus();
	// 			handleAuthorization(statusData.openStatus);
	// 		} catch (error) {
	// 			console.error("Error fetching platform status:", error);
	// 		} finally {
	// 			setLoading(false);
	// 		}
	// 	};
	//
	// 	fetchPlatformStatus();
	// }, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (handleAuthorization(input)) {
			setInput("");
		} else {
			alert("Mot de passe incorrect");
		}
	};

	if (isAuthLoading) {
		return <Spinner />;
	}

	if (isAuthorized) {
		return children;
	}

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100">
			<div className="bg-white p-8 rounded-lg shadow-md w-96">
				<h2 className="text-xl font-semibold text-center mb-4">
					Entrez le mot de passe
				</h2>
				<form onSubmit={handleSubmit}>
					<label className="block mb-4">
						<span className="block text-sm font-medium text-gray-700 mb-1">
							Mot de passe :
						</span>
						<input
							type="password"
							value={input}
							onChange={(e) => setInput(e.target.value)}
							className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
							required
						/>
					</label>
					<button
						type="submit"
						className="w-full bg-startgreen_primary-100 text-white font-semibold py-2 rounded-md hover:bg-blue-700 transition"
					>
						Soumettre
					</button>
				</form>
			</div>
		</div>
	);
};

export default AuthorizeGuard;
