import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import StatsCards from "@pages/(startgreen)/views/(dashboard)/StatsCards";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	fetchCurrentPhase,
	fetchStatistics,
} from "@api/service/startgreen.services.ts";
import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from "ahooks";

export const StartGreenDashboard = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");
	const queryClient = useQueryClient();

	// Fetching submission statistics
	const {
		data: statistics,
		isLoading: isStatisticsLoading,
		isError: isStatisticsError,
	} = useQuery({
		queryKey: ["submissionStatistics"],
		queryFn: fetchStatistics,
		refetchOnWindowFocus: true,
		refetchInterval: 1000 * 60,
		staleTime: 1000 * 60,
	});

	// Fetching current phase
	const {
		data: currentPhase,
		isLoading: isPhaseLoading,
		isError: isPhaseError,
	} = useQuery({
		queryKey: ["currentPhase"],
		queryFn: fetchCurrentPhase,
		refetchOnWindowFocus: true,
		staleTime: 1000 * 60,
	});

	const navItems = [
		{ label: "Dashboard", path: "/startgreen-by-iptic/dashboard" },
		{
			label: "Participants",
			path: "/startgreen-by-iptic/dashboard/participants",
		},
		{ label: "Phases", path: "/startgreen-by-iptic/dashboard/phases" },
		{ label: "Emails", path: "/startgreen-by-iptic/dashboard/emails" },
		{ label: "Settings", path: "/startgreen-by-iptic/dashboard/settings" },
	];
	const [isOpen, setIsOpen] = useState(false);

	// Use document visibility to determine when to refetch
	const documentVisibility = useDocumentVisibility();

	// Debounce function to invalidate queries when document becomes visible
	const { run: debouncedInvalidateQueries } = useDebounceFn(
		() => {
			queryClient.invalidateQueries(["submissionStatistics"]);
			queryClient.invalidateQueries(["currentPhase"]);
		},
		{ wait: 1000 }
	);

	useUpdateEffect(() => {
		if (documentVisibility === "visible") {
			debouncedInvalidateQueries();
		}
	}, [documentVisibility]);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="flex bg-red-[#F3F3F3]">
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={toggleSidebar}
				navItems={navItems}
			/>
			<div className="flex-grow">
				<Navbar toggleSidebar={toggleSidebar} />
				<main className="p-5 space-y-6">
					{/* Pass the fetched statistics and current phase to the StatsCards component */}
					<StatsCards
						statistics={statistics}
						isLoading={isStatisticsLoading || isPhaseLoading}
						isError={isStatisticsError || isPhaseError}
						currentPhase={currentPhase}
					/>
					<hr className="my-8 border-t-2 border-gray-100/10" />
					{/*<ActionButtons />*/}
				</main>
			</div>
		</div>
	);
};

export default StartGreenDashboard;
