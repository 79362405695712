import React, { useEffect, useState } from "react";
import axios from "axios";
import HeaderSection from "@pages/(startgreen)/views/(form)/HeaderSection";
import { appConfig } from "@config/appConfig.ts";
import CandidateForm from "./CandidateForm";
import VisitorForm from "./VisitorForm";

const Spinner = () => (
	<div className="flex justify-center items-center py-20">
		<div className="loader border-t-4 border-startgreen_primary-100 rounded-full w-12 h-12 animate-spin"></div>
	</div>
);

const Application: React.FC = () => {
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		// ------------------- Step 1 ------------------- //
		first_name: "",
		last_name: "",
		// full_name: "", DEL
		email: "",
		phone_number: "",
		region: "",
		age: "",
		gender: "",
		professional_situation: "",
		education_level: "",
		education_level_other: "",
		// 	------------------ //
		development_stage: "",
		project_activity: [],
		project_activity_other: "",
		// -------- //
		employees_number: "",
		sales_revenue: "",
		// ------- //
		support_needs: "",
		support_needs_other: "",
		// -------- //
		support_themes: [],
		support_themes_other: "",
		// ------- //
		hackathon_participation: "",
		// -------- //
		previous_support: "",
		previous_support_details: "",
		// -------- //
		discovery_source: [],
		discovery_source_other: "",
	});

	const [isEligible, setIsEligible] = useState(false);
	const [isPlatformOpen, setIsPlatformOpen] = useState(false);
	const [isPhaseOpen, setIsPhaseOpen] = useState(false);
	const [canApply, setCanApply] = useState<"USER" | "VISITOR" | "NONE">("USER");
	const [loading, setLoading] = useState(true);
	const [phaseName, setPhaseName] = useState("");

	useEffect(() => {
		const fetchPlatformInfo = async () => {
			try {
				const [statusResponse, phaseResponse] = await Promise.all([
					axios.get(appConfig.startGreenUrl + "/status"),
					axios.get(appConfig.startGreenUrl + "/platform/phases/current"),
				]);

				const statusData = statusResponse.data;
				const phaseData = phaseResponse.data;
				const isPlatformOpen = statusData.openStatus === true;
				const isPhaseOpen =
					phaseData.isOpen === true && statusData.applyStatus === true;
				const canApply = phaseData.canApply;

				setIsPlatformOpen(isPlatformOpen);
				setIsPhaseOpen(isPhaseOpen);
				// setCanApply(canApply);
				setPhaseName(phaseData.name);
			} catch (error) {
				console.error("Error fetching platform info", error);
			} finally {
				setLoading(false);
			}
		};

		fetchPlatformInfo();
	}, []);

	const nextStep = () => {
		setStep((prev) => prev + 1);
	};

	const prevStep = () => {
		setStep((prev) => prev - 1);
	};

	return (
		<div className="w-full flex flex-col max-w-[605px] mx-auto py-20 px-[30px] gap-[40px]">
			<HeaderSection />
			{loading ? (
				<Spinner />
			) : isPhaseOpen ? (
				canApply === "USER" ? (
					<CandidateForm
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
						formData={formData}
						setFormData={setFormData}
					/>
				) : canApply === "VISITOR" ? (
					<VisitorForm
						step={step}
						nextStep={nextStep}
						prevStep={prevStep}
						formData={formData}
						setFormData={setFormData}
					/>
				) : (
					<div
						className={
							"bg-white flex items-center justify-center text-center font-bold rounded-md p-6 "
						}
					>
						Le formulaire est actuellement fermé.
					</div>
				)
			) : (
				<div
					className={
						"bg-white flex items-center justify-center text-center font-bold rounded-md p-6"
					}
				>
					Le formulaire est actuellement fermé.
				</div>
			)}
		</div>
	);
};

export default Application;
